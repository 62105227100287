import styled from 'styled-components'

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`

export const DateInput = styled.input`
  margin-top: 0.5rem;
  width: 50%;
`
