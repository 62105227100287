export const blogLinks: LinkItem[] = [
  "Ben's Thoughts",
  'Reviews',
  {
    name: 'Podcast',
    link: 'Down South Boulder Road',
  },
]
export const authorLinks: LinkItem[] = [
  'Books',
  'Short Stories',
  'All Projects',
]
export const generalLinks: LinkItem[] = [
  'theme',
  'about',
  'contact',
  'privacy',
  {
    name: 'home',
    link: '',
  },
]
