import styled from 'styled-components'

export const PageContainer = styled.div`
  position: relative;
`

export const MainContents = styled.div`
  width: 100%;
`

export const SideArea = styled.div`
  position: relative;
  width: 100%;
`
